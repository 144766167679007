/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

:root{
  --light-color-series1:#7cb5ec;
  --light-color-series2:#FF33B2;
  --light-color-series3:#AFE1AF;
  --light-color-series4:#F89880;
  --light-color-series5:violet;
  --dark-color-series2: darkblue;
  --dark-color-series1: maroon;
  --light-bgColor:white;
  --dark-bgColor:lightgrey;
}

.mat-tooltip {
  background-color: blue;
  color: white;
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  top: 100%; 
  left: 50%; 
  transform: translateX(-50%);
  z-index: 1;
}
.network-label{
  padding: 3px;
  border-radius: 2px;
}
.breaker-label {
  border: 2px solid black;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 3px;
  border-radius: 5px;
  box-shadow: 2px 2px 2px #888888;
}
body .toast-top-right {
  top: 50px;
}

